import React, { useContext, useMemo } from 'react';
import { Checkbox } from '@naf/checkbox';
import { Text, TextVariant } from '@naf/text';
import xor from 'lodash/xor';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';
import { List } from '@styled-icons/material/List';
import { Message } from '@naf/input';
import qs from 'qs';
import { useHistory } from 'react-router';
import { Map } from '@styled-icons/material-outlined/Map';
import { Button } from '@naf/button';
import loadable from '@loadable/component';

import ArrowHoverLink from '../components/ArrowHoverLink';
import { Spinner } from '../../../components/spinner/Spinner';
import S from '../Styles';
import { LocationSlugs, useGetNafLocations } from '../../../hooks/useGetNafLocations';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import LocationChips from '../components/LocationChips';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { NafLocationsAppContext } from '../NafLocationsApp';
import { useWindowLocation } from '../../../hooks/useWindowLocation';

const GoogleMaps = loadable(() => import('../components/GoogleMap'));

const createNafCenterUrl = (data: any) => `/bilverksted-og-tester/naf-senter/${data.slug}`;

const NafCenters = () => {
  const data = useContext(NafLocationsAppContext);
  const { ingress, seoConfig } = data || {};
  const url = useWindowLocation();
  const [selectedCounties, setSelectedCounties] = React.useState<string[]>([]);
  const history = useHistory();
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const showMap = params.showMap === 'true';

  const {
    totalCount,
    totalReturned,
    filteredLocations,
    nafLocationsByCounty,
    fetchState,
    error,
    showMore,
    isFiltering,
    totalCountInSelectedCounties,
  } = useGetNafLocations(LocationSlugs.nafCenters, selectedCounties, showMap ? 1000 : undefined);

  const markers = useMemo(
    () =>
      showMap
        ? nafLocationsByCounty.flatMap(({ nafLocations }) =>
            nafLocations
              .filter((l) => l.coordinates !== null)
              .map(({ title, ingress: locationIngress, link, coordinates }) => ({
                lat: coordinates.latitude,
                lng: coordinates.longitude,
                title,
                ingress: locationIngress,
                link: createNafCenterUrl(link.data),
              })),
          )
        : null,
    [nafLocationsByCounty, showMap],
  );

  let content;

  if (fetchState === 'initial' || (fetchState === 'isUpdating' && nafLocationsByCounty.length === 0)) {
    content = <Spinner />;
  } else if (error) {
    content = <Message error>{error.message}</Message>;
  } else if (showMap && markers.filter(Boolean).length > 0) {
    content = (
      <MapWrapper>
        <S.ButtonWrapper>
          <List size={18} />
          <S.MapButton onClick={() => history.push(`?showMap=${!showMap}`)} text="Vis som liste" />
        </S.ButtonWrapper>
        <GoogleMaps markers={markers} />
      </MapWrapper>
    );
  } else {
    content = (
      <Wrapper>
        <S.ButtonWrapper>
          <Map size={18} />
          <S.MapButton onClick={() => history.push(`?showMap=${!showMap}`)} text="Vis som kart" />
        </S.ButtonWrapper>
        <S.CountText>
          <Text tag="span" variant={TextVariant.BodyText}>
            Viser
          </Text>
          <Text tag="span" variant={TextVariant.BodyTextHeader}>{` ${totalCountInSelectedCounties} NAF Senter ${
            selectedCounties.length > 0
              ? `i ${selectedCounties.join(', ').replace(/, ([^,]*)$/, ' og $1')} `
              : `i alle fylker `
          }`}</Text>
          <Text tag="span" variant={TextVariant.BodyText}>
            alfabetisk
          </Text>
        </S.CountText>
        <S.Filter>
          <S.FilterHeader variant={TextVariant.BodyTextHeader}> Fylke</S.FilterHeader>
          <Checkbox
            name="alle"
            checked={selectedCounties.length === 0}
            onChange={() => setSelectedCounties([])}
            label="Alle"
          />
          {nafLocationsByCounty.map(({ county }) => {
            const checked = selectedCounties.includes(county.slug);
            return (
              <Checkbox
                key={county.name}
                name={county.slug}
                checked={checked}
                disabled={county.total === 0}
                onChange={() => {
                  setSelectedCounties(xor(selectedCounties, [county.slug]));
                }}
                label={`${county.name} (${county.total})`}
              />
            );
          })}
        </S.Filter>
        <S.HitList>
          {fetchState === 'isUpdating' && isFiltering ? (
            <Spinner />
          ) : (
            <>
              {filteredLocations.map(({ county, nafLocations }) => (
                <div key={county.name}>
                  <S.ResultHeader variant={TextVariant.BodyTextHeader}>{county.name}</S.ResultHeader>
                  {nafLocations.map((location) => (
                    <S.BorderWrapper key={location.link.data.slug}>
                      <ArrowHoverLink
                        variant={TextVariant.Header2}
                        to={createNafCenterUrl(location.link.data)}
                        text={location.title}
                      />
                    </S.BorderWrapper>
                  ))}
                </div>
              ))}
              <S.ShowMoreButtonWrapper>
                {totalCount && totalReturned < totalCount && (
                  <Button variant="outline" onClick={showMore}>
                    {fetchState === 'isUpdating' ? <Spinner /> : 'Vis flere'}
                  </Button>
                )}
                <p>{`Viser ${totalReturned} av ${totalCount}`}</p>
              </S.ShowMoreButtonWrapper>
            </>
          )}
        </S.HitList>
      </Wrapper>
    );
  }

  return (
    <LayoutWithMainContent
      title="NAF Senter"
      description={ingress || seoConfig?.introduction || 'Liste over NAF Senter i Norge'}
      seoDescription="Her finner du alle NAF-senter i Norge"
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle="NAF Senter"
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      url={url}
    >
      <LocationChips />
      {content}
    </LayoutWithMainContent>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'mapbutton hitcount' 'filter hitlist';
  grid-template-columns: 1fr 5fr;
  grid-gap: ${spacing.space32};
  grid-template-rows: auto auto;
  padding: ${spacing.space32} 0;

  @media (max-width: ${breakpoints.s}) {
    grid-template-areas: 'mapbutton' 'filter' 'hitcount' 'hitlist';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
`;

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${spacing.space32} 0;
`;

export default NafCenters;
